import {configureStore} from '@reduxjs/toolkit'
import appointmentReducer from './slices/athena/appointments'
import departmentReducer from './slices/athena/departments'
import loadDataFromSessionStorage from './slices/athena/dafaultValues'
import getAppointmentData from './slices/athena/getAppoinments'
import appointmentDates from './slices/athena/appointmentDates'
// import { loadDataFromSessionStorage } from './slices/athena/dafaultValues';
export const store = configureStore({
    reducer:{
        appointments:appointmentReducer,
        departments:departmentReducer,
        sessionData:loadDataFromSessionStorage,
        appointmentData:getAppointmentData,
        appointmentDates:appointmentDates
    }
})
